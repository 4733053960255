define("discourse/plugins/discourse-autopost/discourse/models/campaign_source", ["exports", "@ember/object", "discourse-common/utils/decorators", "discourse/plugins/discourse-autopost/discourse/models/campaign_provider"], function (_exports, _object, _decorators, _campaign_provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const values = [{
    provider_id: 1,
    id: 1,
    name: "Channel",
    key: "channel"
  }, {
    provider_id: 2,
    id: 2,
    name: "Feed",
    key: "feed"
  }, {
    provider_id: 3,
    id: 3,
    name: "User",
    key: "user"
  }];
  const CampaignSource = _object.default.extend(dt7948.p({
    provider_id: null,
    name: "",
    key: "",
    _setup() {
      let data = _campaign_provider.default.findById(this.get("id"));
      this.provider_id = data.provider_id;
      this.name = data.name;
      this.key = data.key;
    }
  }, [["method", "_setup", [(0, _decorators.on)("init")]]]));
  CampaignSource.reopenClass({
    findById(id) {
      if (!id) {
        return;
      }
      return values.findBy("id", Number(id));
    },
    filterByProvider(id) {
      return values.filter(function (el) {
        return el.provider_id === id;
      });
    },
    list() {
      return values;
    }
  });
  var _default = _exports.default = CampaignSource;
});