define("discourse/plugins/discourse-autopost/discourse/routes/admin-plugins-autopost-campaigns", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-autopost/discourse/models/campaign"], function (_exports, _ajax, _discourse, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)("/autopost/campaigns.json").then(result => {
        return result.campaigns.map(v => {
          if (("true", "false").includes(v.include_description)) {
            v.include_description = v.include_description === "true";
          }
          if (("true", "false").includes(v.tag_channel)) {
            v.tag_channel = v.tag_channel === "true";
          }
          return _campaign.default.create(v);
        });
      });
    }
  });
});