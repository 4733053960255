define("discourse/plugins/discourse-autopost/discourse/models/campaign", ["exports", "discourse/models/category", "discourse/models/rest", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-autopost/discourse/models/campaign_provider", "discourse/plugins/discourse-autopost/discourse/models/campaign_source"], function (_exports, _category, _rest, _decorators, _I18n, _campaign_provider, _campaign_source) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend(dt7948.p({
    provider_id: null,
    source_id: null,
    key: null,
    category_id: null,
    topic_id: null,
    channel_name: null,
    filter_by_title_keywords: false,
    enable_smart_reply: false,
    include_description: false,
    description_length: 240,
    tag_channel: true,
    default_tags: null,
    owner_username: null,
    subscription_state: "Unknown",
    subscription_expiration_time: null,
    subscription_last_received: null,
    categoryName(categoryId) {
      if (!categoryId) {
        return;
      }
      const category = _category.default.findById(categoryId);
      if (!category) {
        return _I18n.default.t("autopost.choose.deleted_category");
      }
      return category.get("name");
    },
    providerName(providerId) {
      if (!providerId) {
        return;
      }
      return _campaign_provider.default.findById(providerId).name;
    },
    sourceName(sourceId) {
      if (!sourceId) {
        return;
      }
      return _campaign_source.default.findById(sourceId).name;
    }
  }, [["method", "categoryName", [(0, _decorators.default)("category_id")]], ["method", "providerName", [(0, _decorators.default)("provider_id")]], ["method", "sourceName", [(0, _decorators.default)("source_id")]]]));
});