define("discourse/plugins/discourse-autopost/discourse/templates/components/icon-or-name", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if youTubeIcon}}
    <td>{{d-icon "fab-youtube"}}</td>
  {{else}}
    <td>{{model.providerName}}</td>
  {{/if}}
  */
  {
    "id": "c54QAR1N",
    "block": "[[[41,[30,0,[\"youTubeIcon\"]],[[[1,\"  \"],[10,\"td\"],[12],[1,[28,[35,1],[\"fab-youtube\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"td\"],[12],[1,[30,0,[\"model\",\"providerName\"]]],[13],[1,\"\\n\"]],[]]],[1,[28,[32,0],[\"[[\\\"The `youTubeIcon` property path was used in the `discourse/plugins/discourse-autopost/discourse/templates/components/icon-or-name.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.youTubeIcon}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-autopost/discourse/templates/components/icon-or-name.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-autopost/discourse/templates/components/icon-or-name.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});