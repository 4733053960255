define("discourse/plugins/discourse-autopost/discourse/initializers/autopost-init", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api"], function (_exports, _ajax, _ajaxError, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-autopost",
    initialize(container) {
      let siteSettings = container.lookup("site-settings:main");
      let currentUser = container.lookup("service:currentUser");
      if (!siteSettings.autopost_enabled || !currentUser || !currentUser.staff) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        api.addPostMenuButton("summarise", () => {
          return {
            action: "summariseVideo",
            icon: "robot",
            className: "autopost-robot",
            title: "autopost.process_post",
            position: "second-last-hidden" // can be `first`, `last` or `second-last-hidden`
          };
        });
        api.reopenWidget("post", {
          summariseVideo() {
            return (0, _ajax.ajax)(`/autopost/${this.model.id}.json`, {
              type: "POST"
            }).catch(_ajaxError.popupAjaxError);
          }
        });
      });
    }
  };
});