define("discourse/plugins/discourse-autopost/discourse/controllers/icon-or-name", ["exports", "@ember/component", "@ember/utils", "discourse-common/utils/decorators"], function (_exports, _component, _utils, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    youTubeIcon(provider_id) {
      if ((0, _utils.isEmpty)(provider_id)) {
        return false;
      }
      return provider_id === 1;
    }
  }, [["method", "youTubeIcon", [(0, _decorators.default)("model.provider_id")]]]));
});