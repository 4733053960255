define("discourse/plugins/discourse-autopost/discourse/controllers/admin-plugins-autopost-campaigns", ["exports", "@ember/controller", "@ember/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/plugins/discourse-autopost/discourse/models/campaign", "discourse/plugins/discourse-autopost/discourse/models/campaign_provider", "discourse/plugins/discourse-autopost/discourse/models/campaign_source"], function (_exports, _controller, _utils, _ajax, _ajaxError, _decorators, _campaign, _campaign_provider, _campaign_source) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    editing: false,
    providers() {
      return _campaign_provider.default.list();
    },
    sources(provider_id) {
      if ((0, _utils.isEmpty)(provider_id)) {
        return [];
      }
      return _campaign_source.default.filterByProvider(Number(provider_id));
    },
    youTube(provider_id) {
      if ((0, _utils.isEmpty)(provider_id)) {
        return false;
      }
      return provider_id === 1;
    },
    fixDescriptionLength() {
      if (this.get("editing.description_length") !== undefined && !this.get("editing.include_description") && this.get("editing.description_length") !== "" && isNaN(Number(this.get("editing.description_length")))) {
        this.set("editing.description_length", "120");
      }
    },
    invalidNumber(description_length) {
      return isNaN(Number(description_length)) || Math.round(Number(description_length)) !== Number(description_length);
    },
    keyLabel(provider_id, source_id) {
      let provider = _campaign_provider.default.findById(Number(provider_id));
      let source = _campaign_source.default.findById(Number(source_id));
      if (provider && source) {
        return "autopost.campaign.key." + provider.key + "." + source.key;
      }
      return "autopost.campaign.key.invite";
    },
    dependentCells(provider_id, source_id) {
      let provider = _campaign_provider.default.findById(Number(provider_id));
      let source = _campaign_source.default.findById(Number(source_id));
      if (provider && source) {
        return "show-me";
      }
      return "hide-me";
    },
    commandAction(id) {
      if (id) {
        return "update";
      }
      return "create";
    },
    commandLabel(action) {
      return "autopost.campaign.button." + action;
    },
    saveDisabled(key, category_id, topic_id, description_length) {
      return (0, _utils.isEmpty)(key) || (0, _utils.isEmpty)(category_id) === (0, _utils.isEmpty)(topic_id) || isNaN(Number(description_length));
    },
    actions: {
      new() {
        this.set("editing", _campaign.default.create({}));
      },
      cancel() {
        this.set("editing", false);
      },
      create() {
        const campaign = this.get("editing");
        (0, _ajax.ajax)("/autopost/campaigns.json", {
          method: "POST",
          data: campaign.getProperties("provider_id", "source_id", "key", "category_id", "topic_id", "channel_name", "filter_by_title_keywords", "enable_smart_reply", "include_description", "description_length", "default_tags", "tag_channel", "owner_username")
        }).then(() => {
          const model = this.get("model");
          model.pushObject(_campaign.default.create(campaign.getProperties("provider_id", "source_id", "key", "category_id", "topic_id", "channel_name", "filter_by_title_keywords", "enable_smart_reply", "include_description", "description_length", "default_tags", "tag_channel", "owner_username")));
          this.set("editing", false);
        }).catch(_ajaxError.popupAjaxError);
      },
      edit(campaign) {
        this.set("editing", campaign);
      },
      delete(campaign) {
        const model = this.get("model");
        (0, _ajax.ajax)("/autopost/campaigns.json", {
          method: "DELETE",
          data: campaign.getProperties("id")
        }).then(() => {
          const obj = model.find(x => x.get("id") === campaign.get("id"));
          model.removeObject(obj);
        }).catch(_ajaxError.popupAjaxError);
      },
      update() {
        const campaign = this.get("editing");
        (0, _ajax.ajax)("/autopost/campaigns.json", {
          method: "PUT",
          data: campaign.getProperties("id", "provider_id", "source_id", "key", "category_id", "topic_id", "channel_name", "filter_by_title_keywords", "enable_smart_reply", "include_description", "description_length", "default_tags", "tag_channel", "owner_username")
        }).then(() => {
          const model = this.get("model");
          const obj = model.find(x => x.get("id") === campaign.get("id"));
          if (obj) {
            obj.setProperties({});
          }
          this.set("editing", false);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, [["method", "providers", [_decorators.default]], ["method", "sources", [(0, _decorators.default)("editing.provider_id")]], ["method", "youTube", [(0, _decorators.default)("editing.provider_id")]], ["method", "fixDescriptionLength", [(0, _decorators.observes)("editing.description_length", "editing.include_description")]], ["method", "invalidNumber", [(0, _decorators.default)("editing.description_length")]], ["method", "keyLabel", [(0, _decorators.default)("editing.provider_id", "editing.source_id")]], ["method", "dependentCells", [(0, _decorators.default)("editing.provider_id", "editing.source_id")]], ["method", "commandAction", [(0, _decorators.default)("editing.id")]], ["method", "commandLabel", [(0, _decorators.default)("commandAction")]], ["method", "saveDisabled", [(0, _decorators.default)("editing.key", "editing.category_id", "editing.topic_id", "editing.description_length", "editing.include_description")]]]));
});