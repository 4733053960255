define("discourse/plugins/discourse-autopost/discourse/models/campaign_provider", ["exports", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const values = [{
    id: 1,
    name: "YouTube",
    key: "youtube"
  }, {
    id: 2,
    name: "Website",
    key: "website"
  }, {
    id: 3,
    name: "Twitter",
    key: "twitter"
  }];
  const CampaignProvider = _object.default.extend(dt7948.p({
    name: "",
    key: "",
    _setup() {
      let data = CampaignProvider.findById(this.get("id"));
      this.name = data.name;
      this.key = data.key;
    }
  }, [["method", "_setup", [(0, _decorators.on)("init")]]]));
  CampaignProvider.reopenClass({
    findById(id) {
      if (!id) {
        return;
      }
      return values.findBy("id", Number(id));
    },
    list() {
      return values;
    }
  });
  var _default = _exports.default = CampaignProvider;
});